import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Who can benefit from OphthoSim Mobile™?
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Students`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Healthcare Practitioners`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Anyone who is looking to learn about the eye and ophthalmoscopy!`}</MDXTag>
</MDXTag>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Hardware:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Compatibility: any smartphone`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Safe: Eyeform with silicone footprint easily clips onto your phone`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Secure: Safety ring holder for secure grip`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Versatile use: Hand-held, table top, or stand-mounted`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Comes with a functional ophthalmoscope for training purposes`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  