import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Learn Ophthalmoscopy. Anywhere. Anytime. Any Pace.
</Heading>
<MDXTag name="p" components={components}>{`OphthoSim Mobile™ transforms your smartphone into a hand-held ophthalmoscopy simulator delivering a complete cloud-based curriculum.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  